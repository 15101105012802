import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';
// import bgImage from 'common/src/assets/image/charity/milestone-bg.png';
/* ------------------------------------ */
// style for circle loader
/* ------------------------------------ */
const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`;

const grow = keyframes`
	50% {
		transform: scale(1);
	}
`;
const shake = keyframes`
0% {
  transform: translateX(-1px);
}
50% {
  transform: translateX(5px);
}
100% {
  transform: translateX(0);
}
`;
export const CircleLoader = styled.div`
  align-items:center;
  justify-items:center;
  animation: ${rotate} 3s linear infinite;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  transform-origin: bottom center;

  .circle {
    animation: ${grow} 1.5s linear infinite;
    background-color: ${themeGet('colors.primary', '#FDEF00')};
    border-radius: 50%;
    display: inline-block;
    margin: -9px;
    height: 40px;
    width: 40px;
    transform: scale(0);

    &:nth-of-type(2) {
      animation-delay: 0.75s;
      background-color: ${themeGet('colors.white', '#ffffff')};
    }
  }

  &.alt {
    .circle {
      &:nth-of-type(2) {
        background-color: ${themeGet('colors.heading', '#191919')};
      }
    }
  }
`;
const BlockWrapper = styled.div`
  width: 100%;
  margin: 81px 0;
  padding: 60px;
  min-height:356px;
  margin-top:180px;
  // background-repeat: no-repeat;
  // background-size: 100%;
  // border-radius: 10px;
  @media only screen and (max-width: 1440px) {
    margin: 120px 0;
    padding: 20px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 10px;
    margin: 120px 0;
  }
  @media only screen and (max-width: 991px) {
    margin: 40px 0;
    margin-top: 100px;
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px;
    // background-size: cover;
    margin-bottom: 40px;
  }
  #charitySlide {
    .glide__slides {
      height: auto;
      @media only screen and (max-width: 991px) {
        // height: 322px;
      }
      @media only screen and (max-width: 667px) {
        height: auto;
      }
      .glide__slide {
        .slide_image {
          height: auto;
          @media only screen and (max-width: 991px) {
            height:auto;
          }
        }
        h2 {
          // position:absolute;
          // top: 370px;
          font-size: 40px;
          line-height: 70px;
          font-weight: 400;
          color: ${themeGet('colors.heading', '#060F1E')};
          margin: 30px 0;
          @media only screen and (max-width: 1440px) {
            font-size: 40px;
            line-height: 56px;
          }
          @media only screen and (max-width: 1360px) {
            font-size: 40px;
            line-height: 40px;
            margin: 28px 0;
          }
          @media only screen and (max-width: 991px) {
            font-size: 26px;
            line-height: 26px;
            margin: 12px 0;
          }
          &:hover{
            color:#1A73E8;
          }
        }
        img {
          width: 100%;
          max-height: 482px;
          object-fit: fill;
          border-radius: 10px;
          // background-shadow: 60px 60px 100px rgba(175, 182, 194, 0.2);
          @media only screen and (max-width: 991px) {
            object-fit:contain;
            max-height:200px;
          }
        }
        .learn__more-btn {
          margin-top: 40px;
          @media only screen and (max-width: 1440px) {
            margin-top: 25px;
          }
          @media only screen and (max-width: 1360px) {
            margin-top: 20px;
          }
          
          &::before {
            background-color: #fffa9a !important;
          }
         
    
        }
        .learn__more-btn {
          display: inline-flex;
          align-items: center;
          font-family: 'Open Sans', sans-serif;
          color: ${themeGet('colors.heading', '#060F1E')};
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0;
          height: 50px;
          min-width: 151px;
          padding: 0 20px;
          position: relative;
          @media only screen and (max-width: 1360px) {
            font-size: 13px;
          }
      
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 40px;
            width: 72px;
            background-color: ${themeGet('colors.primary', '#FCF22B')};
            transition: all 0.3s ease-out;
          }
      
          .btn_text {
            z-index: 1;
          }
      
          .hyphen {
            width: 20px;
            height: 2px;
            margin-right: 9px;
            background-color: ${themeGet('colors.heading', '#060F1E')};
            transform-origin: 50% 50%;
            z-index: 1;
          }
      
          &:hover {
            &::before {
              width: 100%;
            }
      
            .hyphen {
              animation: ${shake} 1s linear;
            }
          }
        }
        
      }
    }
    
    .glide__controls {
     position: inherit;
      align-items: center;
        justify-content: center;
      // right: calc(100% + 50px);
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1440px) {
        // top: 50px;
      }
      @media only screen and (max-width: 1360px) {
        // right: calc(100% + 40px);
      }
      @media only screen and (max-width: 667px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: inherit;
        right: auto;
      }

      div {
        cursor: pointer;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        min-height: 17px;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        > span {
          display: inline-block;
          width: 30px;
          height: 2px;
          border-radius: 4px;
          background-color: ${themeGet('colors.text', '#294859')};
          position: relative;
          transition: all 0.3s ease;

          &::before,
          &::after {
            content: '';
            display: block;
            width: 14px;
            height: 2px;
            border-radius: 4px;
            background-color: ${themeGet('colors.text', '#294859')};
            position: absolute;
            z-index: 1;
            transition: all 0.3s ease;
          }

          &.next_arrow {
            &::before {
              right: 0;
              transform: rotate(42deg);
              transform-origin: top right;
            }
            &::after {
              right: 0;
              transform: rotate(-42deg);
              transform-origin: 14px 7px;
              width: 11px;
            }
          }

          &.prev_arrow {
            &::before {
              left: 0;
              transform: rotate(-42deg);
              transform-origin: top left;
            }
            &::after {
              left: 0;
              transform: rotate(42deg);
              transform-origin: -3px 6px;
              width: 11px;
            }
          }
        }

        &:hover {
          > span {
            width: 100px;
            background-color:#1A73E8;
            @media only screen and (max-width: 1440px) {
              width: 70px;
            }

            &::before,
            &::after {
              background-color:#1A73E8;
            }
          }
        }
      }
    }
  }
`;

export const MilestoneCard = styled.div`
  max-width: 323px;
  padding: 62px 55px 70px;
  border-radius: 10px;
  background-color: ${themeGet('colors.primary', '#FCF22B')};
  text-align: center;
  @media only screen and (max-width: 1440px) {
    padding: 56px 45px 56px;
  }
  @media only screen and (max-width: 1360px) {
    max-width: 300px;
    padding: 45px 30px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 280px;
    padding: 40px 30px;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: ${themeGet('colors.heading', '#060F1E')};
    margin: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
      line-height: 30px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 15px;
      line-height: 28px;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 70px;
    font-weight: 400;
    color: ${themeGet('colors.heading', '#060F1E')};
    margin: 30px 0;
    @media only screen and (max-width: 1440px) {
      font-size: 40px;
      line-height: 56px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 40px;
      line-height: 40px;
      margin: 28px 0;
    }
    @media only screen and (max-width: 991px) {
      font-size: 32px;
      line-height: 40px;
      margin: 22px 0;
    }
  }

  .learn__more-btn {
    margin-top: 40px;
    @media only screen and (max-width: 1440px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 1360px) {
      margin-top: 40px;
    }

    &::before {
      background-color: #fffa9a !important;
    }
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 920px;
  width: 100%;
  margin: 0 auto 75px;
  @media only screen and (max-width: 1440px) {
    margin: 0 auto 70px;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 auto 50px;
  }
  @media only screen and (max-width: 991px) {
    margin: 0 auto 40px;
  }
  @media only screen and (max-width: 480px) {
    margin: 0 auto 15px;
  }
`;

export const CounterItem = styled.div`
  text-align: center;
  @media only screen and (max-width: 480px) {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 300;
    @media only screen and (max-width: 1440px) {
      font-size: 40px;
      line-height: 46px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 13px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 15px;
    }
  }
`;

export default BlockWrapper;
